export default [
  {
    header: "Management",
    icon: "FolderIcon",
    children: [
      {
        title: "Payment Lists",
        route: "payment-lists",
        icon: "DollarSignIcon",
      },
      {
        title: "Withdraw Lists",
        route: "withdraw-lists",
        icon: "DollarSignIcon",
      },
    ],
  },
];